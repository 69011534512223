import React from "react"
import { graphql, Link } from "gatsby"

// const slugify = require("slugify")

const Sitemap = ({ data: { services, creativeServices, sectors, locations, caseStudies, creativeCaseStudies } }) => {

  //console.log(services);
  //console.log(creativeServices);
  //console.log(sectors);
  //console.log(locations);
  //console.log(caseStudies);
  //console.log(creativeCaseStudies);
  
  return (
    <>
      {/* <SEO
        titleOverride={page.metaTags && page.metaTags.title ? page.metaTags.title : page.title}
        descriptionOverride={page.metaTags && page.metaTags.description ? page.metaTags.description : null}
        pathnameOverride={location.pathname}
        imageOverride={page.metaTags && page.metaTags.image ? page.metaTags.image.url : null}
      /> */}

      {/* <section className="pt-16 pb-16 mb-16 text-white lg:mb-24 bg-secondary">
        <div className="container">
          <div className="w-full mx-auto 2xl:w-10/12">
            <div className="w-full mx-auto text-center md:w-9/12 lg:w-8/12">
              <h1>{ page.heroHeading }</h1>
              <div className="content content--reset md:w-10/12 md:mx-auto" dangerouslySetInnerHTML={{ __html: page.heroExcerpt }}></div>
              <Link className="capitalize btn btn--primary" to="/work/">View our work</Link>
            </div>
          </div>
        </div>
      </section> */}

      <section className="pt-16 pb-10 mb-16 text-white lg:mb-24 bg-secondary">
        <div className="container">
          <div className="w-full mx-auto 2xl:w-10/12">
            <div className="w-full mx-auto text-center md:w-9/12 lg:w-8/12">
              <h1>Sitemap</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-12 overflow-hidden md:mb-16">
        <div className="container">
          <div className="w-full mx-auto md:w-10/12">

            <h2 className="pt-6">Services</h2>
            {services.edges.map(({ node }, index) => {
                return (
                  <Link key={index} to={`/services/${node.slug}/`} className="block clear">
                    <span class="mr-2 inline-block text-primary"><span class="triangle triangle__ triangle__small inline-block"></span></span>
                    {node.title}
                  </Link>
              )
            })}

            <h2 className="pt-6">Web Services</h2>
            {services.edges.map(({node}) => {
              return (
                node.title === "Web Design" &&
                node.treeChildren.map((child, index) => {
                  return (
                    child.ppcOnlyPage === false &&
                    <Link key={index} to={`/services/${node.slug}/${child.slug}/`} className="block clear">
                      <span class="mr-2 inline-block text-primary"><span class="triangle triangle__ triangle__small inline-block"></span></span>
                      {child.title}
                    </Link>
                  )
                })                 
              )              
            })}

            <h2 className="pt-6">Social Media Marketing Services</h2>
            {services.edges.map(({node}) => {
              return (
                node.title === "Social Media Marketing" &&
                node.treeChildren.map((child, index) => {
                  return (
                    <Link key={index} to={`/services/${node.slug}/${child.slug}/`} className="block clear">
                      <span class="mr-2 inline-block text-primary"><span class="triangle triangle__ triangle__small inline-block"></span></span>
                      {child.title}
                    </Link>
                  )
                })                 
              )              
            })}

            <h2 className="pt-6">PPC Services</h2>
            {services.edges.map(({node}) => {
              return (
                node.title === "PPC" &&
                node.treeChildren.map((child, index) => {
                  return (
                    <Link key={index} to={`/services/${node.slug}/${child.slug}/`} className="block clear">
                      <span class="mr-2 inline-block text-primary"><span class="triangle triangle__ triangle__small inline-block"></span></span>
                      {child.title}
                    </Link>
                  )
                })                 
              )              
            })}

            <h2 className="pt-6">SEO Services</h2>
            {services.edges.map(({node}) => {
              return (
                node.title === "SEO" &&
                node.treeChildren.map((child, index) => {
                  return (
                    <Link key={index} to={`/services/${node.slug}/${child.slug}/`} className="block clear">
                      <span class="mr-2 inline-block text-primary"><span class="triangle triangle__ triangle__small inline-block"></span></span>
                      {child.title}
                    </Link>
                  )
                })                 
              )              
            })}

            <h2 className="pt-6">Content Marketing Services</h2>
            {services.edges.map(({node}) => {
              return (
                node.title === "Content Marketing" &&
                node.treeChildren.map((child, index) => {
                  return (
                    <Link key={index} to={`/services/${node.slug}/${child.slug}/`} className="block clear">
                      <span class="mr-2 inline-block text-primary"><span class="triangle triangle__ triangle__small inline-block"></span></span>
                      {child.title}
                    </Link>
                  )
                })                 
              )              
            })}

            <h2 className="pt-6">Creative Services</h2>
            {creativeServices.edges.map(({ node }, index) => {
              return (
                <Link key={index} to={`/creative-services/${node.slug}/`} className="block clear">
                  <span class="mr-2 inline-block text-primary"><span class="triangle triangle__ triangle__small inline-block"></span></span>
                  {node.title}
                </Link>
              )
            })}

            <h2 className="pt-6">Videography Services</h2>
            {creativeServices.edges.map(({node}) => {
              return (
                node.title === "Videography" &&
                node.treeChildren.map((child, index) => {
                  return (
                    <Link key={index} to={`/creative-services/${node.slug}/${child.slug}/`} className="block clear">
                      <span class="mr-2 inline-block text-primary"><span class="triangle triangle__ triangle__small inline-block"></span></span>
                      {child.title}
                    </Link>
                  )
                })                 
              )              
            })}

            <h2 className="pt-6">Sectors</h2>
            {sectors.edges.map(({ node }, index) => {
              return (
                <Link key={index} to={`/sectors/digital-marketing-${node.slug}/`} className="block clear">
                  <span class="mr-2 inline-block text-primary"><span class="triangle triangle__ triangle__small inline-block"></span></span>
                  {node.title}
                </Link>
              )
            })}

            <h2 className="pt-6">Account Manager Locations</h2>
            {locations.edges.map(({ node }, index) => {
              return (
                <Link key={index} to={`/digital-marketing-agency/${node.slug}/`} className="block clear">
                  <span class="mr-2 inline-block text-primary"><span class="triangle triangle__ triangle__small inline-block"></span></span>
                  {node.title}
                </Link>
              )
            })}

            <h2 className="pt-6">Case Studies</h2>
            {caseStudies.edges.map(({ node }, index) => {
              return (
                <Link key={index} to={`/work/${node.slug}/`} className="block clear">
                  <span class="mr-2 inline-block text-primary"><span class="triangle triangle__ triangle__small inline-block"></span></span>
                  {node.title}
                </Link>
              )
            })}

            <h2 className="pt-6">Creative Case Studies</h2>
            {creativeCaseStudies.edges.map(({ node }, index) => {
              return (
                <Link key={index} to={`/work/${node.slug}/`} className="block clear">
                  <span class="mr-2 inline-block text-primary"><span class="triangle triangle__ triangle__small inline-block"></span></span>
                  {node.title}
                </Link>
              )
            })}
          </div>
        </div>
      </section>

      <section className="mb-16 lg:mb-24">
        <p className="px-12 mb-8 text-2xl leading-tight text-center align-middle lg:text-3xl font-display md:px-24">Get in touch today on <a href="tel:01159598900" className="inline-block break-all transition duration-500 ease-in-out text-primary hover:text-primary-dark focus:text-primary-dark contactlink">0115 959 8900</a> or <span className="ml-2 align-middle"><Link to="/contact/" className="text-base btn btn--outline">Get in touch</Link></span></p>
      </section>
    </>
  )
}

export default Sitemap

export const query = graphql`
  query sitemapQuery {
    services: allDatoCmsService(filter: {root: {eq: true}}) {
      edges {
        node {
          slug
          title
          treeChildren {
            slug
            title
            ppcOnlyPage
            treeParent {
              slug
            }
            treeChildren {
              slug
              title
              treeParent {
                slug
              }
            }
          }
        }
      }
    }
    creativeServices: allDatoCmsCreativeService(filter: {root: {eq: true}}) {
      edges {
        node {
          slug
          title
          treeChildren {
            slug
            title
            treeParent {
              slug
            }
            treeChildren {
              slug
              title
              treeParent {
                slug
              }
            }
          }
        }
      }
    }
    sectors: allDatoCmsSector {
      edges {
        node {
          slug
          title
        }
      }
    }
    locations: allDatoCmsLocation {
      edges {
        node {
          slug
          title
        }
      }
    }
    caseStudies: allDatoCmsCaseStudy {
      edges {
        node {
          slug
          title
        }
      }
    }
    creativeCaseStudies: allDatoCmsCreativeCaseStudy {
      edges {
        node {
          slug
          title
        }
      }
    }
  }
`
